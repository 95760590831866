<template>
  <div class="medic-search" :class="type == 'small' ? 'small' : ''">
    <div class="box flex-1 flex-start-start">
      <!-- <div class="medic-search-select" @click="showSelect = !showSelect, showSearchPanel = false">
        全部
        <i class="triangle"></i>
        <ul class="select-box" v-show="showSelect" @click.prevent>
          <li class="list">全部</li>
          <li class="list">中草药</li>
          <li class="list">中成药</li>
        </ul>
      </div> -->
      <div class="medic-search-select flex-start-center" v-if="showSelectPanel">
        <el-select v-model="searchType" :popper-append-to-body="false">
          <el-option class="medic-hover-info" v-for="item in $store.getters.medicTabSelect" :label="item.name" :value="item.key" :key="item.key"></el-option>
          <!-- <el-option :key="1" label="中草药" :value="1"></el-option>
          <el-option :key="2" label="中成药" :value="2"></el-option> -->
        </el-select>
      </div>
      <el-input class="input flex-1" :class="showSelectPanel ? '' : 'no-select'" ref="searchDom" @blur="inputBlur" @focus="inputFocus" :autofocus="true" type="text" placeholder="请输入搜索关键词" v-model="searchText" @keyup.enter.native="search" />
      <div class="medic-search-box flex-start-center" @click="search">搜索
        <img style="margin-left: 6px" width="18px" height="18px" src="../../../assets/medicine/main_search@2x.png" alt="">
      </div>
    </div>
    <!-- 联想词列表 -->
    <!-- <div class="search-content" v-show="showSearchPanel">
      <ul class="search-wrapper">
        <li class="list ellipsis">123<span class="remove" @click.stop="clickPanel">删除</span></li>
        <li class="list ellipsis">123<span class="remove">删除</span></li>
        <li class="list ellipsis">123<span class="remove">删除</span></li>
      </ul>
      <div class="clearAll"><span>清空</span></div>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    cunstomIndex: {
      type: String,
      default: '-1'
    },
    // 是否显示select选择框
    showSelectPanel: {
      type: Boolean,
      default: true
    },
    initSearchText: {
      type: String,
      default: ''
    },
    jump: {
      type: Boolean,
      default: true
    },
    jumpUrl: {
      type: String,
      default: '/medicine/searchHome/searchContent'
    },
    type: {
      type: String,
      default: 'large'
    }
  },
  data() {
    return {
      searchType: '-1',
      searchText: '',
      showSearchPanel: false,
      showSelect: false,
      hiddenPanel: null
    }
  },
  methods: {
    // 点击搜索按钮
    search() {
      // 设置搜索不跳转
      if(!this.jump){
        this.$emit('getSearchContent', {
          searchType: this.searchType,
          searchText: this.searchText
        })
        return;
      }
      let href = ''
      switch (this.medicType) {
        case 0:
          href = '/medicine/searchHome/searchContent'
          break;
        case 1:
          href = '/medicine/herbalMedicine'
          break;
        case 2:
          href = '/medicine/patentMedicine'
          break;
        default:
          break;
      }
      // 当前路由==跳转路由时return 防止报错
      if(this.$route.query.searchText !== this.searchText || String(this.$route.query.searchType) !== String(this.searchType)) {
        this.$router.push({
          path: href,
          query: {
            medicType: '0',
            searchType: this.searchType,
            searchText: this.searchText
          }
        })
        // return
      }
      this.$emit('getSearchContent', {
        searchType: this.searchType,
        searchText: this.searchText
      })
    },
    // input失去焦点tanpan隐藏
    inputBlur() {
      this.hiddenPanel = setTimeout(() => {
        this.showSearchPanel = false;
      }, 200)
    },
    // input获取焦点并且搜索内容有值tanpan显示
    inputFocus() {
      if(this.searchText.length > 0) {
        this.showSearchPanel = true;
      }
    },
    // 点击搜索panel 防止input失去焦点隐藏panel
    clickPanel() {
      clearTimeout(this.hiddenPanel)
      this.showSearchPanel = true;
      this.$refs.searchDom.focus();
    }
  },
  computed: {
    ...mapState({
      medicType: state => state.medic.medicType
    })
  },
  watch: {
    // searchText(val) {
    //   if(val.length > 0) {
    //     this.showSearchPanel = true;
    //     this.showSelect = false;
    //   } else {
    //     this.showSearchPanel = false;
    //   }
    // },
    cunstomIndex: {
      handler(val) {
        this.searchType = val
      },
      immediate: true
    },
    initSearchText: {
      handler(val) {
        this.searchText = val
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scope>
.medic-search{
  position: relative;
  // width: 740px;
  height: 60px;
  line-height: 60px;
  background: #FFFFFF;
  border-radius: 2px;
  border: 1px solid #D7000F;
  box-sizing: border-box;
  .medic-search-select{
    margin: 6px 0;
    // padding-left: 18px;
    position: relative;
    height: 48px;
    line-height: 48px;
    width: 65px;
    border-right: 1px solid #E3E3E3;
    cursor: pointer;
    box-sizing: border-box;
    .el-select{
      .el-input__inner{
        width: 55px;
        text-align: center;
        padding: 0 8px;
        border: none;
        color: #333333;
        font-size: 12px;
        font-weight: 600;
      }
      .el-input__suffix{
        right: -10px;
        top: -2px;
        .el-select__caret{
          background-image: url('../../../assets/medicine/arrow_up@2x.png');
          background-size: 8px 4px;
          background-position: center center;
          background-repeat: no-repeat;
        }
        .el-icon-arrow-up{
          &:before{
            display: none;
          }
        }
      }
      .el-select-dropdown{
        position: absolute!important;
        margin: 0;
        top: 43px!important;
        left: -1px!important;
        width: 66px!important;
        box-shadow: none;
        background: #FFFFFF;
        border-left: 1px solid #D7000F;
        border-bottom: 1px solid #D7000F;
        border-right: 1px solid #D7000F;
        border-top: none;
        border-radius: 0;
        z-index: 10!important;
        .popper__arrow{
          display: none;
        }
        .el-select-dropdown__list{
          padding: 0;
          .el-select-dropdown__item{
            text-align: center;
            padding: 0;
            width: 56px;
            height: 25px;
            line-height: 25px;
            margin: 0 auto;
            margin-bottom: 5px;
            border-radius: 2px;
            cursor: pointer;
            font-size: 12px;
            color: #999999;
            &:hover{
              background: #F2F2F2;
            }
          }
        }
      }
    }
    .triangle{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 2px;
      margin-left: 3px;
      width: 0;
      height: 0;
      border-top: 4px solid #333333;
      border-right: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-left: 4px solid transparent;
    }
    .select-box{
      position: absolute;
      display: none;
      width: 68px;
      top: 42px;
      left: -1px;
      list-style: none;
      background: #FFFFFF;
      border-left: 1px solid #D7000F;
      border-bottom: 1px solid #D7000F;
      border-right: 1px solid #D7000F;
      text-align: center;
      box-sizing: border-box;
      z-index: 10;
      .list{
        width: 56px;
        height: 25px;
        line-height: 25px;
        margin: 0 auto;
        margin-bottom: 5px;
        border-radius: 2px;
        cursor: pointer;
        font-size: 12px;
        color: #999999;
        &:hover{
          background: #F2F2F2;
        }
      }
    }
  }
  .input{
    position: relative;
    height: 58px;
    z-index: 10;
    &.no-select{
      &::before{
        display: none;
      }
      &::after{
        display: none;
      }
    }
    &::before{
      position: absolute;
      left: -1px;
      top: 0;
      content: '';
      width: 1px;
      height: 100%;
      background: #ffffff;
      padding: 5px 0;
      box-sizing: border-box;
    }
    &::after{
      position: absolute;
      left: -1px;
      top: 50%;
      margin-top: -24px;
      content: '';
      width: 1px;
      height: 48px;
      background: #E3E3E3;
      box-sizing: border-box;
    }
    .el-input__inner{
      padding: 0 11px;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      height: 58px;
      line-height: 58px;
      border-radius: 0;
      border: none;
    }
    &:-ms-input-placeholder {
      color: #CCCCCC;
      font-weight: 400;
    }/* Internet Explorer 10+ */

    &::-webkit-input-placeholder {
        color: #CCCCCC;
      font-weight: 400;
    }/* WebKit browsers */

    &::-moz-placeholder {
      font-weight: 400;
        color: #CCCCCC;
    }/* Mozilla Firefox 4 to 18 */

    &:-moz-placeholder {
      font-weight: 400;
        color: #CCCCCC;
    }/* Mozilla Firefox 19+ */
  }
  .medic-search-box{
    position: relative;
    top: -1px;
    cursor: pointer;
    padding-left: 38px;
    width: 140px;
    height: 60px;
    background: #D7000F;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    box-sizing: border-box;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 600;
  }
  .search-content{
    position: absolute;
    top: 58px;
    left: -1px;
    width: calc(100% - 140px);
    border-left: 1px solid #D7000F;
    border-right: 1px solid #D7000F;
    border-bottom: 1px solid #D7000F;
    background: #FFFFFF;
    z-index: 8;
    .search-wrapper{
      margin: 0 10px;
      padding: 6px 0;
      list-style: none;
      border-top: 1px solid #E4E4E4;
      .list{
        cursor: pointer;
        max-width: 100%;
        padding: 0 7px 0 6px;
        margin: 0 -5px;
        line-height: 34px;
        font-weight: 400;
        color: #333333;
        font-size: 16px;
        &:hover{
          background: #F2F2F2;
          border-radius: 2px;
          .remove{
            display: block;
          }
        }
        .remove{
          display: none;
          float: right;
          color: #999999;
          font-size: 16px;
          &:hover{
            color: #D7000F;
          }
        }
      }
    }
    .clearAll{
      margin-top: -8px;
      text-align: right;
      color: #999999;
      line-height: 22px;
      padding: 0 12px 12px;
      font-size: 16px;
      &>span{
        cursor: pointer;
        &:hover{
          color: #D7000F;
        }
      }
    }
  }
  &.small{
    height: 40px;
    line-height: 40px;
    .medic-search-select{
      height: 28px;
      line-height: 28px;
    }
    .input{
      &::before{
        height: 38px;
      }
      &::after{
        height: 28px;
      }
      .el-input__inner{
        height: 38px;
        line-height: 38px;
      }
    }
    .medic-search-box{
      height: 40px;
    }
    .search-content{
      top: 38px;
    }
    .medic-search-select {
      .el-select {
        .el-input__inner{
          height: 28px;
        }
        .el-input__suffix{
          margin-top: 2px;
          display: flex;
          align-items: center;
          height: 28px;
        }
        .el-select-dropdown{
          top: 32px!important;
        }
      }
    }
  }
}
</style>