<template>
  <div class="medic-home">
    <div class="medic-wrapper">
      <h1 class="medic-title">医学界中药帮你查</h1>
      <div class="medic-input">
        <medic-input @getSearchContent="getSearchContent" :jump="true" />
      </div>
      <div class="medic-hot-search">
        <div class="title">热搜榜</div>
        <ul class="hot-list" v-cloak>
          <!-- <span class="tag">热</span> -->
          <li
            class="list flex-start-center"
            v-for="(item,index) in searchWordList"
            :key="index"
            @click="searchHotText(item)"
          >
            <span class="top">{{index+1}}</span>
            <span class="text medic-hover-info">{{item | hotSearch}}</span>
            <Tag
              v-if="index < 3"
              style="margin-left: 11px"
              :productItem="{clhot: 399}"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import MedicInput from '@/views/herbalMedicine/components/SearchInput'
import Tag from '@/views/herbalMedicine/components/tag'
import { poisonDrugSearchWordList } from '@/api/herbalMedicine'
export default {
  data () {
    return {
      searchWordList: []
    }
  },
  created() {
    // 有毒药品热搜索词列表
    this.getPoisonDrugSearchWordList();
  },
  methods: {
    // 有毒药品热搜索词列表
    getPoisonDrugSearchWordList () {
      poisonDrugSearchWordList({
        limit: 10,
        start: 1
      }).then(res => {
        this.searchWordList = res.wordList;
      })
    },
    // 点击搜索按钮
    getSearchContent(scope) {
      console.log('搜索内容', scope)
    },
    // 搜索热门榜
    searchHotText(text) {
      this.$router.push({
        path: '/medicine/searchHome/searchContent',
        query: {
          searchType: '-1',
          searchText: text
        }
      })
    }
  },
  filters: {
    hotSearch(val) {
      if(val && val.length > 20) {
        val = val.substring(0, 20) + '...'
      }
      return val
    },
  },
  components: {
    MedicInput,
    Tag
  }
}
</script>

<style lang="scss" scope>
.medic-home {
  padding: 40px;
  .medic-wrapper {
    width: 740px;
    margin: 0 auto;
    .medic-title {
      text-align: center;
      margin-bottom: 25px;
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      line-height: 33px;
    }
    .medic-input {
      margin-bottom: 50px;
    }
    .medic-hot-search {
      .title {
        margin-bottom: 19px;
        padding: 0 8px;
        height: 28px;
        line-height: 28px;
        background: #f7f7f7;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
      .hot-list {
        padding: 0 10px;
        height: 160px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        .list {
          width: 50%;
          margin-bottom: 10px;
          cursor: pointer;
          & > .text {
            max-width: 280px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            color: #333333;
            line-height: 20px;
          }
          & > .top {
            margin-right: 7px;
            text-align: center;
            width: 15px;
            font-size: 14px;
            font-weight: 600;
            color: #999999;
            line-height: 20px;
            // &.one {
            //   color: #d7000f;
            // }
            // &.two {
            //   color: #de5456;
            // }
            // &.three {
            //   color: #f58c23;
            // }
          }
          & > .tag {
            text-align: center;
            margin-left: 11px;
            width: 16px;
            height: 16px;
            line-height: 16px;
            background: #d7000f;
            border-radius: 2px;
            font-size: 12px;
            color: #ffffff;
          }
          &:nth-child(1) {
            & > .top {
              color: #d7000f;
            }
          }
          &:nth-child(2) {
            & > .top {
              color: #de5456;
            }
          }
          &:nth-child(3) {
            & > .top {
              color: #f58c23;
            }
          }
        }
      }
    }
  }
}
</style>